import classNames from 'classnames/bind'; // Import classNames và bind
import styles from './Footer.module.scss'; // Import SCSS module

const cx = classNames.bind(styles); // Liên kết với SCSS modul

function Footer() {
  return (
    <footer className={cx('footer')}>
      <div className={cx('footer__content')}>
        <p className={cx('footer__text')}>© 2024 My Beautiful Website. All Rights Reserved.</p>
        <div className={cx('footer__links')}>
          <a href="/about" className={cx('footer__link')}>
            About
          </a>
          <a href="/contact" className={cx('footer__link')}>
            Contact
          </a>
          <a href="/privacy" className={cx('footer__link')}>
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
