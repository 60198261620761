import styles from './HeaderLogout.module.scss';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faBarsProgress,
  faBuildingColumns,
  faGamepad,
  faIgloo,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Tippy from '@tippyjs/react/headless';
import 'tippy.js/dist/tippy.css';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

function HeaderLogout() {
  const [formType, setFormType] = useState(null); // null | 'login' | 'register'
  const [formData, setFormData] = useState({
    userName: '',
    password: '',
    avatar: '',
  });

  const [message, setMessage] = useState(''); // Hiển thị thông báo cho người dùng
  const [backgrMessage, setBackgrMessage] = useState(false);
  const [user, setUser] = useState(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user);

    return user ? user : null;
  });
  // Lưu thông tin người dùng khi đăng nhập thành công

  // Kiểm tra trạng thái đăng nhập khi trang tải lại

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value.trimStart(),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formType === 'register') {
        // Gửi request đăng ký
        const res = await axios.post('https://shopnaruto.vn/auth/register', formData);
        console.log(res);

        setMessage('Đăng ký thành công!');
        setTimeout(() => {
          setMessage('');
        }, 1300);
        setBackgrMessage(true);
        setFormType('login'); // Chuyển sang form đăng nhập
      } else if (formType === 'login') {
        // Gửi request đăng nhập
        const res = await axios.post('https://shopnaruto.vn/auth/login', formData);
        console.log(res);

        setMessage(`${res.data.message}`);
        setTimeout(() => {
          setMessage('');
        }, 1300);
        setBackgrMessage(true);
        setUser(JSON.parse(res.config.data)); // Lưu thông tin người dùng vào state
        // console.log(JSON.parse(res.config.data));

        localStorage.setItem('user', res.config.data); // Lưu thông tin người dùng vào localStorage
        setFormType(null); // Ẩn form
      }
    } catch (error) {
      console.log(error.response.data.error);
      const logError = error.response.data.error;

      setMessage(`${logError}, vui lòng thử lại!`);
      setBackgrMessage(false);
      setTimeout(() => {
        setMessage('');
      }, 1300);
    }
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    setUser(null); // Đăng xuất, xóa thông tin người dùng
    localStorage.removeItem('user'); // Xóa thông tin người dùng khỏi localStorage
    navigate('/'); // Điều hướng về trang chủ ("/")
  };

  return (
    <div className={cx('Header')}>
      <div className={cx('logo_web')}></div>
      <div className={cx('all_Header-item')}>
        {/* Các mục menu */}

        <div className={cx('Header_item', 'itemADR')}>
          <Link to="/">
            <h3 className={cx('text_icon')}>Home</h3>
          </Link>
          <FontAwesomeIcon className={cx('font-icon', 'icon')} icon={faIgloo} />
        </div>

        {user ? (
          <>
            <div className={cx('Header_item', 'itemADR')}>
              <Link to="/article">
                <h3 className={cx('text_icon')}>Bài viết</h3>
              </Link>
              <FontAwesomeIcon className={cx('font-icon', 'icon')} icon={faBuildingColumns} />
            </div>
            <div className={cx('Header_item', 'itemADR')}>
              <Link to="/createPost">
                <h3 className={cx('text_icon')}>Tạo bài viết</h3>
              </Link>
              <FontAwesomeIcon className={cx('font-icon', 'icon')} icon={faBarsProgress} />
            </div>

            <div className={cx('Header_item', 'itemADR')}>
              <Link to="/Game">
                <h3 className={cx('text_icon')}>Game</h3>
              </Link>
              <FontAwesomeIcon className={cx('font-icon', 'icon')} icon={faGamepad} />
            </div>
          </>
        ) : null}

        <div className={cx('Header_item', 'itemADR')}>
          <Link to="/video">
            <h3 className={cx('text_icon')}>Video</h3>
          </Link>
          <FontAwesomeIcon className={cx('font-icon', 'icon')} icon={faVideo} />
        </div>
      </div>

      {/* Hiển thị thông tin người dùng khi đã đăng nhập */}
      {user ? (
        <div className={cx('user-info')}>
          <span className={cx('user-name')}>{user.userName}</span>
          <div>
            <Tippy
              delay={[0, 1300]} // 0 giây show ngay, 500s sau mới ẩn
              placement="bottom-end" // vị trí hiện
              interactive // cho phép select vào phần tử
              offset={[0, 20]}
              // visible={true} // điều kiện để hiển thị
              render={(attrs) => (
                <div tabIndex={-1} {...attrs} className={cx('tippy-box')}>
                  <ul className={cx('listItem_user')}>
                    <Link to="/login/information">
                      <li className={cx('item_user')}>Thông tin cá nhân</li>
                    </Link>
                    <Link to="/">
                      <li className={cx('item_user')}>Lịch sử đăng nhập</li>
                    </Link>
                    <Link to="/">
                      <li className={cx('item_user')}>Đổi mật khẩu</li>
                    </Link>

                    <li onClick={handleLogout} className={cx('item_user')}>
                      Đăng xuất
                    </li>

                    <li className={cx('item_user')}>Thêm ảnh</li>
                  </ul>
                </div>
              )}
            >
              <div className={cx('div_user-avatar')}>
                <img
                  className={cx('user-avatar')}
                  src={user.avatar || 'https://cellphones.com.vn/sforum/wp-content/uploads/2023/10/avatar-trang-4.jpg'}
                  alt="avatar"
                />
              </div>
            </Tippy>
          </div>
        </div>
      ) : (
        <div className={cx('Login')}>
          <span onClick={() => setFormType('register')} className={cx('text_login')}>
            Login
          </span>
        </div>
      )}

      <div>
        <Tippy
          delay={[0, 1300]} // 0 giây show ngay, 500s sau mới ẩn
          placement="bottom-end" // vị trí hiện
          interactive // cho phép select vào phần tử
          offset={[0, 20]}
          // visible={true} // điều kiện để hiển thị
          render={(attrs) => (
            <div tabIndex={-1} {...attrs} className={cx('tippy-box')}>
              <ul className={cx('listItem_user')}>
                <Link to="/">
                  <li className={cx('item_user')}>Home</li>
                </Link>
                {user ? (
                  <>
                    <Link to="/article">
                      <li className={cx('item_user')}>Bài viết</li>
                    </Link>
                    <Link to="/createPost">
                      <li className={cx('item_user')}>Tạo bài viết</li>
                    </Link>
                    <Link to="/Game">
                      <li className={cx('item_user')}>Game</li>
                    </Link>
                  </>
                ) : null}
                <Link to="/video">
                  <li className={cx('item_user')}>Video</li>
                </Link>
              </ul>
            </div>
          )}
        >
          <div>
            <FontAwesomeIcon className={cx('navItem')} icon={faBars} />
          </div>
        </Tippy>
      </div>

      {/* Form */}
      {formType && (
        <form onSubmit={handleSubmit} className={cx('form_infor')}>
          <div onClick={() => setFormType(null)} className={cx('click_out-form')}>
            <span className={cx('text_click-out')}>Exit</span>
          </div>
          <div className={cx('div_form-infor')}>
            <div className={cx('res_log')}>
              <div
                onClick={() => setFormType('register')}
                className={cx('resgiter', { active: formType === 'register' })}
              >
                <span className={cx('text_resgiter')}>ĐĂNG KÝ</span>
              </div>
              <div onClick={() => setFormType('login')} className={cx('login', { active: formType === 'login' })}>
                <span className={cx('text_login-user')}>ĐĂNG NHẬP</span>
              </div>
            </div>
            <div className={cx('div_input_form')}>
              <input
                name="userName"
                value={formData.userName}
                onChange={handleInputChange}
                className={cx('input_form')}
                required
                type="text"
                placeholder="Tên của bạn"
              />
              <input
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className={cx('input_form')}
                required
                type="password"
                placeholder="Mật khẩu"
              />
              <input
                name="avatar"
                value={formData.avatar}
                onChange={handleInputChange}
                className={cx('input_form')}
                required
                type="text"
                placeholder="nhập url ảnh"
              />
            </div>
            <div className={cx('submit_form')}>
              <input
                type="submit"
                className={cx('input_submit')}
                value={formType === 'register' ? 'Đăng ký' : 'Đăng nhập'}
              />
            </div>
          </div>
        </form>
      )}

      {/* Hiển thị thông báo */}
      {message && (
        <div style={{ backgroundColor: backgrMessage ? 'green' : 'red' }} className={cx('message')}>
          {message}
        </div>
      )}
    </div>
  );
}

export default HeaderLogout;
