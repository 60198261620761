import classNames from 'classnames/bind';
import styles from './createPost.module.scss';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);
function CreatePost() {
  const localUser = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [dataForm, setFormData] = useState({
    title: '',
    img: '',
    des: '',
    userName: localUser.userName,
    avatar: localUser.avatar,
  });
  const [messages, setMessages] = useState({
    message: '',
    status: '',
  });

  const handleInputFormPost = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value.trimStart(),
    }));
  };

  const handleSubmitFormPost = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://shopnaruto.vn/post/createPost', dataForm);
      console.log(res);
      setFormData({
        title: '',
        img: '',
        des: '',
        userName: localUser.userName,
        avatar: localUser.avatar,
      });
      const message = res.data.message;
      const status = res.status;
      setMessages({ message: message, status: status });
      setTimeout(() => {
        setMessages({
          message: '',
          status: '',
        });
      }, 2000);
      navigate('/article');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={cx('createPost')}>
      <div className={cx('title_gif')}></div>

      <div className={cx('div_form-CreatePost')}>
        <form onSubmit={handleSubmitFormPost} className={cx('form_CreatePost')}>
          <input
            value={dataForm.title}
            onChange={handleInputFormPost}
            type="text"
            placeholder="nhập tiêu đề bài viết"
            name="title"
            required
          />

          <input
            value={dataForm.img}
            onChange={handleInputFormPost}
            type="text"
            placeholder="thêm link ảnh vô bài viết"
            name="img"
            required
          />
          <input
            value={dataForm.des}
            onChange={handleInputFormPost}
            type="text"
            placeholder="nhập nội dung bài viết"
            name="des"
            required
          />
          <input className={cx('submit_form-CreatePost')} type="submit" />
        </form>
      </div>

      {/* message */}
      {messages.message ? (
        <div className={cx('div_message')}>
          <div style={{ backgroundColor: messages.status < 300 ? 'green' : 'red' }} className={cx('div_text-message')}>
            <span className={cx('text_message')}>{messages.message}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CreatePost;
