import './App.scss';
import Global from './Global';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Information from './Information';
import HeaderLogout from './HeaderLogout';
import Content from './Content';
import Footer from './Footer';
import CreatePost from './Post/createPost/createPost';
import Posts from './Post/article';
function App() {
  return (
    <Router>
      <div className="App">
        <Global>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <HeaderLogout />
                  <Content />
                  <Footer />
                </>
              }
            />
            <Route
              path="/login/information"
              element={
                <>
                  <HeaderLogout />
                  <Information />
                  <Footer />
                </>
              }
            />
            <Route
              path="/createPost"
              element={
                <>
                  <HeaderLogout />
                  <CreatePost />
                  <Footer />
                </>
              }
            />
            <Route
              path="/article"
              element={
                <>
                  <HeaderLogout />
                  <Posts />
                  <Footer />
                </>
              }
            />
          </Routes>
        </Global>
      </div>
    </Router>
  );
}

export default App;
