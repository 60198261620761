import styles from './Posts.module.scss';
import classNames from 'classnames/bind';
import axios from 'axios';
import { useEffect, useState } from 'react';

const cx = classNames.bind(styles);
function Posts() {
  const [posts, setPost] = useState(null);

  const fetchPosts = async () => {
    try {
      const res = await axios.get('https://shopnaruto.vn/post/GetPosts');
      setPost(res.data);
      console.log(res.data);
    } catch (error) {
      console.error('Error fetching posts:', error); // In lỗi ra console
    }
  };

  // Gọi hàm
  useEffect(() => {
    fetchPosts();
  }, []); // [] đảm bảo chỉ gọi một lần khi component mount

  return (
    <div className={cx('Posts')}>
      <div className={cx('nav_posts', 'hiden_nav-posts')}></div>

      <div className={cx('show_posts')}>
        {posts === null ? (
          <div>No posts found.</div>
        ) : (
          posts.map((post, i) => (
            <div key={i} className={cx('posts_item')}>
              <div className={cx('div_user-post')}>
                <img className={cx('img_posts')} src={post.avatar} />

                <span className={cx('user_post-name')}>{post.userName}</span>
              </div>

              <div className={cx('post_list')}>
                <div className={cx('div_title-post-list')}>
                  <h1 className={cx('text_post-list')}>{post.title}</h1>
                </div>

                <div className={cx('div_img-post-list')}>
                  <img className={cx('img_posts-list')} src={post.img} />
                </div>

                <div className={cx('div_des_post-list')}>
                  <h4 className={cx('des_post-list')}>{post.des}</h4>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Posts;
