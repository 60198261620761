import classNames from 'classnames/bind';
import styles from './Information.module.scss';
import { useState } from 'react';
const cx = classNames.bind(styles);

function Information() {
  const [out, setOut] = useState(false);
  const [infor, setInfor] = useState(() => {
    const in4 = JSON.parse(localStorage.getItem('user'));
    console.log(in4.userName);
    return in4 ?? 'chưa có tên nhé';
  });

  const handleOut = () => {
    setOut(false);
  };

  const handleUpdateInfor = () => {
    setOut(true);
  };

  return (
    <>
      <div className={cx('Information')}>
        <div className={cx('List_Information')}>
          <div className={cx('div_yourName')}>
            <h1 className={cx('yourName')}>{infor.userName}</h1>
          </div>
          <ul className={cx('List_items')}>
            <li className={cx('item_infor')}>Nghề nghiệp</li>

            <li className={cx('item_infor')}>
              <div className={cx('div_item-infor')}>
                SỐ ĐIỆN
                THOẠIiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii
              </div>
            </li>
            <li className={cx('item_infor')}>NĂM SINH</li>
            <li className={cx('item_infor')}>GIỚI TÍNH</li>
            <li className={cx('item_infor')}>ĐỊA CHỈ</li>
          </ul>
        </div>

        <div className={cx('img_Information')}>
          <div onClick={handleUpdateInfor} className={cx('update_infor')}>
            <span className={cx('update_text-infor')}>Cập nhật thông tin</span>
          </div>

          <img src={infor.avatar} alt="" />
        </div>
      </div>

      {/* form điền thông tin */}
      <form style={{ display: out ? 'flex' : 'none' }} className={cx('form_infor')}>
        <div onClick={handleOut} className={cx('click_out-form')}>
          <span className={cx('text_click-out')}>Exit</span>
        </div>
        <div className={cx('div_form-infor')}>
          <div className={cx('name_form')}>Thông tin</div>
          <div className={cx('div_input_form')}>
            <input className={cx('input_form')} required type="text" placeholder="Tên của bạn" />
            <input className={cx('input_form')} required type="number" placeholder="Số điện thoại" />
            <input className={cx('input_form')} required type="date" placeholder="Năm sinh" />
            <select className={cx('input_form')} id="gender" name="gender">
              <option value="nam">Nam</option>
              <option value="nữ">Nữ</option>
            </select>
            <input className={cx('input_form')} required type="text" placeholder="Địa chỉ" />
          </div>
          <div className={cx('submit_form')}>
            <input type="submit" className={cx('input_submit')} required></input>
          </div>
        </div>
      </form>
    </>
  );
}

export default Information;
