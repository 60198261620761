import { useState, useRef } from 'react';
import styles from './Music.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

function Music() {
  // Dữ liệu playlist
  const [playlist] = useState([
    { id: 1, title: 'Song 1', src: '/music/amtham.mp3' },
    { id: 2, title: 'Song 2', src: '/music/edm.mp3' },
    { id: 3, title: 'Song 3', src: '/music/sonthuy.mp3' },
  ]);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const audioRef = useRef(null);

  // Chức năng chuyển bài hát
  const playNext = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex + 1) % playlist.length);
  };

  const playPrevious = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex - 1 + playlist.length) % playlist.length);
  };

  const handleEnded = () => {
    playNext();
  };

  return (
    <div className={cx('audio-player')}>
      <div className={cx('audio-player__container')}>
        <h1 className={cx('header')}>Audio Playlist</h1>
        <audio
          ref={audioRef}
          src={playlist[currentSongIndex].src}
          controls
          onEnded={handleEnded}
          autoPlay
          className={cx('audio-player__controls')}
        />
        <div className={cx('audio-player__buttons')}>
          <button onClick={playPrevious} className={cx('btn', 'btn-prev')}>
            Back
          </button>
          <button onClick={() => audioRef.current.play()} className={cx('btn', 'btn-play')}>
            Play
          </button>
          <button onClick={() => audioRef.current.pause()} className={cx('btn', 'btn-pause')}>
            Pause
          </button>
          <button onClick={playNext} className={cx('btn', 'btn-next')}>
            Next
          </button>
        </div>
        <ul className={cx('audio-player__playlist')}>
          {playlist.map((song, index) => (
            <li
              key={song.id}
              className={cx('playlist-item', { active: index === currentSongIndex })}
              onClick={() => setCurrentSongIndex(index)}
            >
              {song.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Music;
