import classNames from 'classnames/bind';
import styles from './Content.module.scss';
import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF, SoftShadows, Environment, Text, Html } from '@react-three/drei';
import Music from './Music';
import { useEffect, useRef, useState } from 'react';
const cx = classNames.bind(styles);

function Content() {
  // âm nhạc
  const [playlist] = useState([
    { id: 1, title: 'Song 1', src: '/music/amtham.mp3' },
    { id: 2, title: 'Song 2', src: '/music/edm.mp3' },
    { id: 3, title: 'Song 3', src: '/music/sonthuy.mp3' },
  ]);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const audioRef = useRef(null);

  const playNext = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex + 1) % playlist.length);
  };

  const playPrevious = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex - 1 + playlist.length) % playlist.length);
  };

  const handleEnded = () => {
    playNext();
  };

  // Component để tải và hiển thị mô hình 3D
  const Model = ({ modelPath }) => {
    const { scene, animations } = useGLTF(modelPath);
    const mixer = useRef(null);

    // Nếu mô hình có hoạt ảnh, sử dụng AnimationMixer để chơi hoạt ảnh
    useEffect(() => {
      if (animations && animations.length > 0) {
        mixer.current = new THREE.AnimationMixer(scene);
        animations.forEach((clip) => mixer.current.clipAction(clip).play());
      }
    }, [animations, scene]);

    // Cập nhật hoạt ảnh
    useFrame((state, delta) => {
      if (mixer.current) mixer.current.update(delta);
    }); // Tải mô hình

    return <primitive object={scene} scale={1} />;
  };

  return (
    <>
      <Canvas style={{ height: '600px', width: '100vw', backgroundColor: 'white' }}>
        {/* Thêm ánh sáng */}
        <ambientLight intensity={6.2} />
        {/* <directionalLight position={[10, 10, 5]} intensity={1} /> */}
        <pointLight position={[5, 5, 5]} intensity={1} color="white" /> {/* Ánh sáng điểm */}
        {/* Ánh sáng chiếu chéo tạo bóng */}
        <spotLight position={[5, 10, 5]} intensity={1} angle={Math.PI / 6} penumbra={1} castShadow />
        <directionalLight position={[0, 10, 5]} intensity={0.6} castShadow />
        {/* Hiển thị mô hình */}
        {/* Điều khiển xoay và phóng to */}
        <OrbitControls
          enableZoom={true} // Cho phép zoom bằng chuột
          zoomSpeed={2} // Điều chỉnh tốc độ zoom
          minDistance={0.5} // Khoảng cách tối thiểu khi zoom
          maxDistance={70} // Khoảng cách tối đa khi zoom
          enablePan={true} // Cho phép kéo
          panSpeed={1} // Tốc độ kéo, giá trị mặc định là 1
          enableRotate={true} // Cho phép xoay
        />
        {/* <fog attach="fog" args={['white', fogDensity, fogDistance]} /> */}
        {/* Bật soft shadows cho bóng */}
        <SoftShadows />
        {/* Môi trường ánh sáng để làm cho vật thể chân thật hơn */}
        <Environment preset="sunset" />
        {/* Vẽ chữ */}
        {/* <Text
        fontSize={2} // Kích thước chữ
        color="red" // Màu chữ
        position={[7, 2, -2]} // Vị trí trong không gian 3D
        anchorX="center" // Căn chỉnh ngang
        anchorY="middle" // Căn chỉnh dọc
        bevelEnabled={true} // Bật bevel để làm cho các góc của chữ mượt mà
        bevelSize={0.1} // Độ mượt của bevel
        bevelThickness={0.3} // Độ dày của bevel (điều này giúp tạo chữ dày hơn)
        extrude={0.5} // Độ dày của chữ 3D, tạo chiều sâu cho chữ
      >
        WEB-DEV
      </Text> */}
        <Model modelPath="../../models/3d9.glb" />
      </Canvas>

      <img style={{ width: '100vw', imageRendering: 'crisp-edges' }} src="imgs/food2.gif" alt="" />

      <img style={{ width: '100vw', imageRendering: 'crisp-edges' }} src="imgs/food3.gif" alt="" />

      {/* âm nhạc */}
      <Music />
      {/* <video controls width="600">
      <source src="path-to-video.mp4" type="video/mp4" />
      Trình duyệt của bạn không hỗ trợ thẻ video.
    </video> */}
    </>
  );
}

export default Content;
